body {
  padding-top: 80px; /* Adjust based on the actual height of your header */
}

main {
  min-height: 80vh;
}

/* Header */

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030; /* Ensure it stays above other content */
}

.navbar {
  transition: all 0.5s ease-in-out;
}

header .dropdown-menu.show {
  text-align: center;
  border: 1px solid gray;
}

.search-box {
  color: white; /* Sets the text color */
}

/* Sets the placeholder color */
.search-box::placeholder {
  color: #ccc; /* Light gray placeholder text, adjust as needed */
}

/* For cross-browser compatibility, you might want to add vendor prefixes */
.search-box::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
}
.search-box::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
}
.search-box:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
}
.search-box:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 4.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  margin-bottom: 0;
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.card-container {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  transition: transform 0.2s ease-in-out; /* Smooth transition for hover effect */
}

/* Slightly scale the card on hover for a lifting effect */
.card-container:hover {
  transform: scale(1.05);
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

/* Product description in banner*/
.product-description p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-carousel-name {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 5px;
}

.bannerVideoParent {
  position: relative;
  overflow: hidden;
  width: 100%;
  /* Adjust the height as needed or use padding-bottom for aspect ratio */
  height: 100vh;
  margin-top: -80px;
}

.bannerVideoParent-standalone {
  position: relative;
  overflow: hidden;
  width: 65%;
  /* Adjust the height as needed or use padding-bottom for aspect ratio */
  height: 55vh;
  margin: auto;
  border-radius: 0.5rem; /* Rounded corners */
}

.bannerVideoParent video {
  position: absolute;
  top: -10%; /* Adjust as needed to crop the top */
  left: -10%; /* Adjust as needed to crop the sides */
  width: 120%; /* Increase width to zoom in */
  height: 120%; /* Increase height to zoom in */
  transform: scale(1.6); /* Scale the video */
}

.bannerVideoParent-standalone video {
  position: absolute;

  transform: scale(1.4); /* Scale the video */
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay to ensure text is readable */
  z-index: 1;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2rem;
  max-width: 600px; /* Ensures the text doesn’t span too wide */
  margin: auto;
}

/* Brands component*/
.brands-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates four columns */
  gap: 20px; /* Space between logos */
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: auto;
}

.brands-grid img {
  border-radius: 0.25rem; /* Rounded corners */
  transition: transform 0.2s; /* Smooth scaling effect */

  /* Optional: Add a box shadow for better visibility */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

  /* Optional: Add a border to separate logos */
  border: 1px solid #000;

  /* responsive for small screems*/
  max-width: 100%;
  height: auto;
}

.brand-logo {
  width: 100%; /* Make logos responsive */
  height: auto;
  transition: transform 0.2s; /* Smooth scaling effect */
}

.brand-logo:hover {
  transform: scale(1.1); /* Slightly enlarge logos on hover */
}

/* Whatsapp Button */
.whatsapp-button {
  position: fixed; /* Fixed positioning relative to the viewport */
  right: 15px; /* 20px from the right */
  background-color: #25d366; /* WhatsApp brand color */
  color: white; /* Icon color */
  padding: 7px; /* Padding around the icon */
  border-radius: 50%; /* Circular button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional: adds shadow for better visibility */
  display: flex; /* Ensures the icon is centered within the button */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-decoration: none; /* Removes underline from the icon/link */
  z-index: 1000;
}

.wb-on-normal {
  bottom: 15px;
}

.wb-on-mobile {
  bottom: 75px;
}

.mobile-font {
  font-size: 0.7rem;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker green on hover for visual feedback */
}

.whatsapp-link2 {
  background-color: #25d366; /* WhatsApp brand color */
  color: white; /* Icon color */
  padding: 10px; /* Padding around the icon */
  border-radius: 1rem; /* Circular button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Optional: adds shadow for better visibility */
  display: flex; /* Ensures the icon is centered within the button */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-decoration: none; /* Removes underline from the icon/link */
  transition: scale 0.2s; /* Smooth scaling effect */
}

.whatsapp-link2:hover {
  background-color: #128c7e; /* Darker green on hover for visual feedback */
  scale: 1.1;
}

/*Footer*/
.group-hover:hover,
.group-hover:hover .text-primary {
  color: #fff; /* Replace with your desired hover color */
  cursor: pointer; /* Change cursor to indicate clickable area */
}

/* Category card for categorySlider component */

.category-image {
  width: 100%; /* Make the image width responsive to the container size */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the container without stretching */
  border-radius: 0.25rem; /* Match Bootstrap's border-radius */
}

.category-card {
  transition: transform 0.3s ease;
}

.category-card:hover {
  transform: scale(1.05); /* Adjust scale value as needed */
}

.category-card .category-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  color: white;
  padding: 10px;
  text-align: center;
  border-bottom-left-radius: 0.25rem; /* Match Bootstrap's border-radius */
  border-bottom-right-radius: 0.25rem; /* Match Bootstrap's border-radius */
}

.category-text {
  font-weight: bold;
  font-size: 1.25rem;
}

.carousel-indicators {
  display: none;
}

/* styles.css */
.scrollable-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* for momentum scrolling on mobile */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  /* Hide scrollbar for chrome */
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrollable-row {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
}

.scrollable-row::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.scrollable-row {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.category-card {
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
  margin-right: 15px; /* Adjust spacing between items */
  /* Set a fixed width or use width from content */
  /* responsive width */
  width: 250px;

  /* Optional: Add a box shadow for better visibility */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.scroll-container-controls {
  position: relative;
}

.scroll-left,
.scroll-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.scroll-left {
  left: 0;
}

.scroll-right {
  right: 0;
}

.product-animation {
  position: fixed;
  animation: moveToCart 2s forwards;
}

.link-style {
  cursor: pointer;
  text-decoration: underline;
}

.fixed-mobile-nav {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1030;
}

.nav-link.active-nav-link {
  font-weight: bold;
  color: #000;
}

@keyframes moveToCart {
  0% {
    top: /* position of button */ ;
    left: /* position of button */ ;
    width: 100px;
    height: 100px;
  }
  100% {
    top: 0;
    left: calc(100vw - 30vw);
    width: 20px;
    height: 20px;
  }
}

/* Responsive s */
@media (max-width: 768px) {
  .bannerVideoParent {
    height: 100vh;
  }
  .bannerVideoParent video {
    top: -20%;
    left: -20%;
    width: 140%;
    height: 140%;
    transform: scale(3);
  }

  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1rem;
  }

  .carousel-caption h2 {
    /*make the font smaller*/
    font-size: 0.9rem;
  }

  /*Brands logos*/
  .brands-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

/* Hide arrows on smaller screens */
@media (max-width: 1199px) {
  .scroll-left,
  .scroll-right {
    display: none;
  }
}
